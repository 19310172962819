<template>
  <div class="page">
    <div class="banner">
      <img
        src="./img/banner.png"
        alt=""
        @click="goPath('teachersAndStudentsIntroduce')"
      />
    </div>
    <div class="main-title">
      <div class="title-l">优秀作品</div>
    </div>
    <div class="goodthing" @click="goPath('worksOfExcellence')">
      <img src="./img/goodThing.png" alt="" />
    </div>
    <div class="main-title">
      <div class="title-l">课程预约</div>
      <div class="title-c" @click="goPath('myAppointment')">我的预约</div>
      <img
        class="title-r"
        src="./img/arrow.png"
        alt=""
        @click="goPath('myAppointment')"
      />
    </div>
    <div class="main-box">
      <div class="weekBox">
        <div
          class="weekItem"
          v-for="(item, index) in weekList"
          :key="index"
          @click="changeWeek(item, index)"
        >
          <div class="str">{{ list[item.index].sText }}</div>
          <div :class="['date', { nowDate: item.active }]">
            {{ item.num }}
          </div>
        </div>
      </div>
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="classItem"
          v-for="(item, index) in listData"
          @click="goPath('courseAppointmentDetail', item)"
          :key="index"
        >
          <div class="classItem-top">
            <div class="name">{{ item.className }}</div>
            <div class="str">去查看</div>
            <img src="./img/right-arrow.png" alt="" />
          </div>
          <div class="place">地点：{{ item.classAddress }}</div>
          <div class="maxNum">时间：{{ item.time }}</div>
        </div>
      </v-list>
    </div>
    <!-- <div class="btn" @click="goPath('courseAppointmentDetail')">课程预约</div> -->
  </div>
</template>

<script>
import { classAppointmentList } from "./api.js";
export default {
  data() {
    return {
      weekList: [],
      list: [
        { sText: "日", fullText: "星期日" },
        { sText: "一", fullText: "星期一" },
        { sText: "二", fullText: "星期二" },
        { sText: "三", fullText: "星期三" },
        { sText: "四", fullText: "星期四" },
        { sText: "五", fullText: "星期五" },
        { sText: "六", fullText: "星期六" },
      ],
      nowDay: "",
      nowIndex: null,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    if (this.$route.query.latitude) {
      localStorage.setItem("latitude", this.$route.query.latitude);
    }
    if (this.$route.query.longitude) {
      localStorage.setItem("longitude", this.$route.query.longitude);
    }
    let date = new Date();
    this.nowDay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    this.weekList = this.getWeekDataList();
    console.log(this.weekList);
    this.weekList.forEach((item) => {
      if (this.getStr(item.num) == this.getStr(this.nowDay)) {
        item.active = true;
        this.nowIndex = item.index;
      }
    });
  },
  methods: {
    changeWeek(row) {
      this.weekList.forEach((item, index) => {
        item.active = false;
      });
      this.nowIndex = row.index;
      row.active = true;
      this.listData = [];
      this.requestData.curPage = 1;
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        week: this.list[this.nowIndex].fullText,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios
        .get(`${classAppointmentList}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          }
        });
    },
    goPath(name, data) {
      if (name == "courseAppointmentDetail") {
        this.$router.push({
          name: name,
          query: {
            dataStr: JSON.stringify(data),
          },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    getStr(data) {
      return String(data);
    },
    getWeekDataList() {
      //根据日期获取本周周一~周日的年-月-日
      let weekList = [];
      let date = new Date();
      //判断本日期是否为周日，获取本周一日期
      if (date.getDay() == "0") {
        date.setDate(date.getDate() - 6);
      } else {
        date.setDate(date.getDate() - date.getDay() + 1);
      }
      let myDate = date.getDate();
      let myMonth = date.getMonth() + 1;
      if (date.getDate() < 10) {
        myDate = "0" + myDate;
      }
      if (date.getMonth() + 1 < 10) {
        myMonth = "0" + myMonth;
      }
      weekList.push({
        num: myDate,
        index: date.getDay(),
        active: false,
      });
      // 获取周二以后日期
      for (let i = 0; i < 6; i++) {
        date.setDate(date.getDate() + 1);
        myDate = date.getDate();
        myMonth = date.getMonth() + 1;
        if (date.getDate() < 10) {
          myDate = "0" + myDate;
        }
        if (date.getMonth() + 1 < 10) {
          myMonth = "0" + myMonth;
        }
        weekList.push({
          num: myDate,
          index: date.getDay(),
          active: false,
        });
      }
      return weekList;
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 90px;
  .btn {
    text-align: center;
    line-height: 74px;
    width: 620px;
    height: 74px;
    background: #93ccf1;
    border-radius: 10px;
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
  }
  .main-box {
    margin-top: 48px;
    padding: 34px 20px 0;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px 4px rgba(237, 237, 237, 0.5);
    border-radius: 8px;
    .classItem {
      height: 252px;
      background: #f3f8fc;
      border-radius: 8px;
      margin-bottom: 14px;
      box-sizing: border-box;
      padding: 0 34px;
      .place {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 20px;
        padding-top: 18px;
      }
      .maxNum {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .classItem-top {
        height: 96px;
        box-sizing: border-box;
        border-bottom: 2px solid #e7e7e7;
        display: flex;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        .str {
          color: #4295e6;
          flex: 1;
          text-align: right;
        }
        img {
          margin-left: 10px;
          width: 14px;
          height: 22px;
        }
      }
    }
    .weekBox {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 30px;
      .nowDate {
        color: #4295e6 !important;
      }
      .date {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .str {
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 12px;
      }
    }
  }
  .goodthing {
    height: 192px;
    margin: 34px 0 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .main-title {
    display: flex;
    align-items: center;
    .title-r {
      width: 14px;
      height: 22px;
      margin-left: 12px;
    }
    .title-c {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
    .title-l {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      flex: 1;
    }
  }
  .banner {
    height: 302px;
    margin-bottom: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>